import * as Slot from 'BaxterScript/version/web/core/Slot';
import { ConfigurationService } from 'BaxterScript/version/web/configuration/ConfigurationService';
import { SlotForContainerFinder } from 'BaxterScript/version/web/core/services/SlotForContainerFinder';
import { ContainerParamsRepository } from 'BaxterScript/version/web/core/repositories/inmemoroy/ContainerParamsRepository';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';

/**
 * @deprecated
 * Extend Slot, Lifecycle, etc. entities instead or create a new one.
 * Use globalThis.Baxter.state as storage.
 */
export class SlotRenderedHandler {
  constructor(
    private configurationService: ConfigurationService,
    private slotForContainerFinder: SlotForContainerFinder,
    private containersParamsRepository: ContainerParamsRepository
  ) {
    this.configurationService = configurationService;
    this.slotForContainerFinder = slotForContainerFinder;
    this.containersParamsRepository = containersParamsRepository;
  }

  async handle(pageId, containerId, slotId, status, slot, isEmpty, providerId, creationTillRenderedTime) {
    console.info(
      '[SLOTS][SLOTRENDEREDHANDLER][HANDLE]',
      pageId,
      containerId,
      slotId,
      status,
      slot,
      isEmpty,
      providerId
    );
    newRelicMetrics.reportMetric(NewRelicMetric.AD_RENDERED, {
      isEmpty,
      providerId,
      lazyLoad: status?.lazyLoad,
      creationTillRenderedTime,
    });
    if (isEmpty && this.configurationService.isFallbackEnabledFor(pageId, containerId)) {
      console.debug(
        '[SLOTS][SLOTRENDEREDHANDLER][HANDLE] fallback',
        pageId,
        containerId,
        slotId,
        status,
        slot,
        isEmpty,
        providerId
      );
      this.slotForContainerFinder.setEmptySlot(pageId, containerId, slotId);
      const params = this.containersParamsRepository.findBy(pageId, containerId);
      await Slot.set(containerId, params, true);
    }
  }
}
