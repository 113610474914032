import { Debounce } from 'BaxterScript/helper/event/Debounce';
import * as LifeCycle from 'BaxterScript/version/web/core/Lifecycle';
import * as State from 'BaxterScript/version/web/core/State';
import * as Interstitial from 'BaxterScript/version/web/provider/googleads/Interstitial';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';

export const addListener = (
  event: string,
  element: Element | Window | Document,
  callback: (...args: unknown[]) => {} | void
) => {
  if (element?.addEventListener) {
    element.addEventListener(event, callback, false);
  }
};

export const removeListener = (
  event: string,
  element: Element | Window | Document,
  callback: (...args: unknown[]) => {} | void
) => {
  if (element?.removeEventListener) {
    element.removeEventListener(event, callback);
  }
};

const handleResize = () => {
  addListener(
    'resize',
    window,
    Debounce(async () => {
      try {
        console.info('[SLOTS][EVENT][RESIZE]');
        await State.setUserActive(true);
        await LifeCycle.onResized();
        Interstitial?.closeModalsWithInvalidSize?.();
      } catch (e) {
        console.error('[SLOTS][EVENT][RESIZE][DEBOUNCE]', e);
        newRelicMetrics.reportError(NewRelicError.EVENT_HANDLE_RESIZE_ERROR, { message: (e as Error).message });
      }
    }, 300)
  );
};

const handleUrlChange = () => {
  addListener('popstate', window, async () => {
    try {
      console.info('[SLOTS][EVENT][POPSTATE]');
      await State.setUserActive(true);
      State.clearInterstitialActions();
      Interstitial?.removeInterstitialModal?.();
    } catch (e) {
      console.error('[SLOTS][EVENT][POPSTATE]', e);
      newRelicMetrics.reportError(NewRelicError.EVENT_HANDLE_URL_CHANGE_ERROR, { message: (e as Error).message });
    }
  });
};

const handleContainerIntersect = () => {
  State.setGeneralObserver();
};

async function onchange(event: Event) {
  try {
    const v = 'visible';
    const h = 'hidden';
    const eventMap = {
      focus: v,
      focusin: v,
      pageshow: v,
      blur: h,
      focusout: h,
      pagehide: h,
    };
    const isVisible =
      event.type in eventMap ? eventMap[event.type] === 'visible' : !(event.currentTarget as HTMLElement)?.hidden;
    if (!isVisible) {
      await State.setUserActive(isVisible);
    }
  } catch (e) {
    console.error('[SLOTS][EVENT][ONCHANGE]', e);
    newRelicMetrics.reportError(NewRelicError.EVENT_HANDLE_USER_ACTIVE_ERROR, { message: (e as Error).message });
  }
}

const setUserActive = async (value) => {
  try {
    await State.setUserActive(value);
  } catch (e) {
    console.error('[SLOTS][EVENT][SETUSERACTIVE]', e);
    newRelicMetrics.reportError(NewRelicError.EVENT_HANDLE_USER_ACTIVE_ERROR, { message: (e as Error).message });
  }
};

const handleUserActive = () => {
  let hidden = 'hidden';
  // standards:
  /* eslint-disable no-cond-assign */
  if (hidden in document) document.addEventListener('visibilitychange', onchange);
  else if ((hidden = 'mozHidden') in document) document.addEventListener('mozvisibilitychange', onchange);
  else if ((hidden = 'webkitHidden') in document) document.addEventListener('webkitvisibilitychange', onchange);
  else if ((hidden = 'msHidden') in document) document.addEventListener('msvisibilitychange', onchange);
  /* eslint-enable no-cond-assign */
  // all others:
  // eslint-disable-next-line no-multi-assign
  else {
    window.onpageshow = onchange;
    window.onpagehide = onchange;
    window.onfocus = onchange;
    window.onblur = onchange;
  }

  // set the initial state (but only if browser supports the Page Visibility API)
  if (document[hidden] !== undefined) {
    // @ts-ignore
    onchange({ type: document[hidden] ? 'blur' : 'focus', currentTarget: document });
  }

  // add other event listeners
  // addListener("pageshow", document, async (event) => State.setUserActive(true));
  addListener('pagehide', document, async () => setUserActive(false));
  // addListener("pageshow", window,async (event) => State.setUserActive(true));
  addListener('pagehide', window, async () => setUserActive(false));
  // addListener("mouseover", document, async (event) => State.setUserActive(true));
  addListener('mousemove', document, async () => setUserActive(true));
  addListener('scroll', document, async () => setUserActive(true));

  // start inactivity timer
  setInterval(async () => setUserActive(false), 10 * 1000);
};

export const bootstrap = () => {
  handleResize();
  handleUrlChange();
  handleContainerIntersect();
  handleUserActive();
};
