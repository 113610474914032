import * as Html from 'BaxterScript/helper/browser/Html';
import * as Event from 'BaxterScript/version/web/core/Event';
import * as State from 'BaxterScript/version/web/core/State';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Config } from 'BaxterScript/types/Config';

export const webpackExclude = (config: Config): boolean => !config.app?.adblock?.enabled;

const addBlockThroughListener = () => {
  Event.addListener('BTAADetection', window, (event) => {
    try {
      console.info('[SLOTS][ADBLOCK][BTAADETECTIONLISTENER]', event);
      const { detail } = event as { detail: { ab: boolean } };
      if (detail.ab) {
        State.setAdblockStatus(true);
        newRelicMetrics.reportMetric(NewRelicMetric.ADBLOCK_BTA);
      }
    } catch (e) {
      console.error('[SLOTS][ADBLOCK][BTAADETECTIONLISTENER]', e);
      newRelicMetrics.reportError(NewRelicError.EVENT_HANDLE_ADBLOCK_DETECT_ERROR, { message: (e as Error).message });
    }
  });
};

const addPaListener = () => {
  Event.addListener('AcceptableAdsInit', window, (event) => {
    try {
      console.info('[SLOTS][ADBLOCK][ACCEPTABLEADSINITLISTENER]', event);
      const { detail } = event as { detail: boolean };
      if (detail) {
        State.setAdblockStatus(true);
        newRelicMetrics.reportMetric(NewRelicMetric.ADBLOCK_ACCEPTABLE);
      }
    } catch (e) {
      console.error('[SLOTS][ADBLOCK][ACCEPTABLEADSINITLISTENER]', e);
      newRelicMetrics.reportError(NewRelicError.EVENT_HANDLE_ADBLOCK_DETECT_ERROR, { message: (e as Error).message });
    }
  });
};

const bootstrap = () => {
  Html.addScriptToHead(`baxter-script-blockthrough`, 'https://btloader.com/tag?o=5162929816076288&upapi=true');
  addBlockThroughListener();
  addPaListener();
};

export default {
  bootstrap,
};
