export enum NewRelicMetric {
  CONSENT_RESTRICT_DATA_PROCESSING = 'BaxterConsentRestrictDataProcessing',
  CONSENT_DONT_RESTRICT_DATA_PROCESSING = 'BaxterConsentDontRestrictDataProcessing',
  CONSENT_MISSING_ACTIVE_GROUPS = 'BaxterConsentMissingActiveGroups',
  SCRIPT_INITIALIZED = 'BaxterInitialized',
  AD_RENDERED = 'BaxterSlotRendered',
  AD_IMPRESSION = 'BaxterSlotImpression',
  CONTAINER_NO_MATCHING_SLOT = 'BaxterContainerNoMatchingSlot',
  INTERSTITIAL_INVALID_FREQUENCY_CAP = 'BaxterInterstitialInvalidFrequencyCap',
  INTERSTITIAL_SHOW_MODAL = 'BaxterInterstitialShowModal',
  INTERSTITIAL_INVALID_PATHNAME = 'BaxterInterstitialInvalidPathname',
  INTERSTITIAL_INVALID_SIZE = 'BaxterInterstitialInvalidSize',
  INTERSTITIAL_CREATE_MODAL = 'BaxterInterstitialCreateModal',
  INTERSTITIAL_INSERT_MODAL = 'BaxterInterstitialInsertModal',
  INTERSTITIAL_CLOSE_MODAL = 'BaxterInterstitialCloseModal',
  GOOGLEADS_DESTROY_SLOTS_IN_REMOVE_DESTROYED_NOTHING = 'BaxterGoogleAdsDestroySlotsInRemoveDestroyedNothing',
  GOOGLEADS_DESTROY_SLOTS_IN_CLEAR_DESTROYED_NOTHING = 'BaxterGoogleAdsDestroySlotsInClearDestroyedNothing',
  PREBID_CURRENCY_CONFIG = 'BaxterPrebidCurrencyConfig',
  PREBID_REQUESTED_BIDS = 'BaxterPrebidRequestedBids',
  PREBID_SUCCESSFULLY_RETURNED = 'BaxterPrebidSuccessfullyReturned',
  PREBID_TIMED_OUT = 'BaxterPrebidTimedOut',
  APS_REQUESTED_BIDS = 'BaxterApsRequestedBids',
  APS_SUCCESSFULLY_RETURNED = 'BaxterApsSuccessfullyReturned',
  APS_TIMED_OUT = 'BaxterApsTimedOut',
  BIDDERS_APS_SENT_AD_SERVER_REQUEST = 'BaxterBiddersApsSentAdServerRequest',
  BIDDERS_PREBID_SENT_AD_SERVER_REQUEST = 'BaxterBiddersPrebidSentAdServerRequest',
  BIDDERS_TIMEOUT_SENT_AD_SERVER_REQUEST = 'BaxterBiddersTimeoutSentAdServerRequest',
  GOOGLEIMA_LOAD = 'BaxterGoogleImaLoad',
  GOOGLEIMA_AUTOPLAY_WAS_PREVENTED = 'BaxterGoogleImaAutoplayWasPrevented',
  GOOGLEIMA_AUTOPLAY_RESULT_UNDEFINED = 'BaxterGoogleImaAutoplayResultUndefined',
  GOOGLEIMA_AUTOPLAY = 'BaxterGoogleImaAutoplay',
  GOOGLEIMA_ADS_ERROR = 'BaxterGoogleImaAdsError',
  GOOGLEIMA_PLAY = 'BaxterGoogleImaPlay',
  GOOGLEIMA_PLAYER_ERROR = 'BaxterGoogleImaPlayerError',
  GOOGLEIMA_ERROR_FALLBACK = 'BaxterGoogleImaErrorFallback',
  GOOGLEIMA_ERROR_RESETING_PLAYER = 'BaxterGoogleImaErrorResetingPlayer',
  GOOGLEIMA_ADS_READY = 'BaxterGoogleImaAdsReady',
  GOOGLEIMA_TIMEOUT_FALLBACK = 'BaxterGoogleImaTimeoutFallback',
  GOOGLEIMA_ADS_MANAGER_LOADED = 'BaxterGoogleImaAdsManagerLoaded',
  GOOGLEIMA_DEPENDENCIES_FALLBACK = 'BaxterGoogleImaDependenciesFallback',
  GOOGLEIMA_ADSERROR_FALLBACK = 'BaxterGoogleImaAdserrorFallback',
  STICKY_STICK = 'BaxterStickyStick',
  STICKY_UNSTICK = 'BaxterStickyUnstick',
  STICKY_CLOSE = 'BaxterStickyClose',
  ADMANAGER_STATIC_DSA_MODAL = 'BaxterAdManagerStaticDsaModal',
  SCRIPT_ON_LOAD_TMP = 'BaxterScriptOnLoadTmp',
  ADBLOCK_BTA = 'BaxterAdblockBTA',
  ADBLOCK_ACCEPTABLE = 'BaxterAdblockAcceptableAdsInit',
}
