import * as Strings from 'BaxterScript/helper/string/String';
import * as Objects from 'BaxterScript/helper/object/Object';
import * as Html from 'BaxterScript/helper/browser/Html';
import * as State from 'BaxterScript/version/web/core/State';
import * as Request from 'BaxterScript/helper/browser/Request';
import { buildSlotRenderedEvent } from 'BaxterScript/version/web/core/SlotRenderedEventListener';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { AdManagerStaticSlot, Slot } from 'BaxterScript/types/Slot';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Config } from 'BaxterScript/types/Config';
import { DSA_STYLES, getDsaCompliantStaticAd } from 'BaxterScript/version/web/provider/admanager-static/DsaTemplate';
import { AdManagerStaticConfig } from 'BaxterScript/types/ProviderSettings/AdManagerStatic';
import Placeholder from '../../feature/Placeholder';

export const id: Providers = Providers.AD_MANAGER_STATIC;

export const webpackExclude = (config: Config): boolean =>
  !(
    Object.values(config.slots.provider?._ ?? {}).includes(id) ||
    Object.values(config.slots.provider ?? {}).includes(id)
  );

export const styles = (config: Config) => (webpackExclude(config) ? '' : DSA_STYLES);

export const transform = async (
  pageId: string,
  containerId: string,
  slotId: string,
  params: TargetingParams
): Promise<Partial<Slot>> => {
  console.info('[SLOTS][ADMANAGER STATIC][TRANSFORM]', pageId, containerId, slotId, params);
  const slot = {
    path: '',
    variables: {},
    targeting: {} as TargetingParams,
    styling: '',
    dsaLabelCss: '',
    dsaIconColor: '',
    dsaIconBackgroundColor: '',
  } as Partial<AdManagerStaticSlot>;
  const configService = globalThis.Baxter.context.configurationService;
  if (configService.hasTargetingFor(id)) {
    const targetingMap = configService.getTargetingMapFor(id, pageId, containerId, slotId);
    slot.targeting = Objects.parseMap(targetingMap, params || {}) as TargetingParams;
    (slot.targeting as TargetingParams).timestamp = new Date().getTime();
  }
  if (configService.hasPathFor(id)) {
    const pathMapping = configService.getPathMapFor(id, pageId, containerId, slotId);
    const pathString = Strings.parseMap(pathMapping, params || {});
    const parts = pathString.split('/');
    slot.path = parts.reduce((path: string, part: string) => (part !== '' ? `${path}/${part}` : path), '');
    const config = configService.getById(
      (globalThis.Baxter.config.slots?.providerSettings?.[id] as AdManagerStaticConfig)?.path,
      pageId,
      containerId,
      slotId
    );
    slot.dsaLabelCss = config?.dsaLabelCss;
    slot.dsaIconColor = config?.dsaIconColor;
    slot.dsaIconBackgroundColor = config?.dsaIconBackgroundColor;
  }
  return slot;
};

export const create = async (slot: AdManagerStaticSlot) => {
  console.info('[SLOTS][ADMANAGER STATIC][CREATE]', slot);
  const url = `${globalThis.Baxter.config.providers[Providers.AD_MANAGER_STATIC]?.settings?.assetPath}${slot.path}`;
  const div = Html.getElementById(slot.innerId);
  if (div) {
    div.innerHTML = '';
    div.style.display = '';

    if (!slot.status.lazyLoad || slot.status.visible) {
      slot.external = true;
      let html;
      try {
        html = await Request.get(url);
      } catch (err) {
        console.error('[SLOTS][ADMANAGER STATIC][CREATE] fetching ad error', err);
        newRelicMetrics.reportError(NewRelicError.ADMANAGER_STATIC_FETCH_AD_ERROR, {
          message: (err as Error).message,
          providerId: id,
          slotId: slot.id,
        });
      }
      if (html) {
        try {
          slot.filled = true;
          Object.keys(slot.targeting || {}).forEach((key) => {
            const regex = new RegExp(`\\\${targeting.${key}\\}`, 'g');
            html = html.replace(regex, slot.targeting?.[key] || '');
          });
          html = html.replace(/\$\{.*?}/g, '');
          const trackingDiv = document.createElement('div');
          trackingDiv.id = `native_ad_frame_${slot.id}`;
          trackingDiv.innerHTML = html.replace(/<script[^>]*>(?:[^<]+|<(?!\/script>))*<\/script>/gi, '');
          if (globalThis.Baxter.config.providers[Providers.AD_MANAGER_STATIC]?.dsa?.enabled) {
            console.debug('[SLOTS][ADMANAGER STATIC][CREATE] use DSA template wrapper');
            const dsaStaticAd = getDsaCompliantStaticAd({
              ...globalThis.Baxter.config.providers[Providers.AD_MANAGER_STATIC]?.dsa,
              staticCreative: trackingDiv,
              dsaLabelCss: slot.dsaLabelCss,
              dsaIconColor: slot.dsaIconColor,
              dsaIconBackgroundColor: slot.dsaIconBackgroundColor,
              pageId: slot.pageId,
              containerId: slot.containerId,
              slotId: slot.id,
            });
            div.appendChild(dsaStaticAd);
          } else {
            console.debug('[SLOTS][ADMANAGER STATIC][CREATE] no DSA wrapper');
            div.appendChild(trackingDiv);
          }
          const head = document.getElementsByTagName('head')[0];
          const parser = document.createElement('div');
          parser.innerHTML = html;
          const scripts = parser.getElementsByTagName('script');
          Object.keys(scripts || []).forEach((key) => {
            const js = scripts[key];
            const script = document.createElement('script');
            script.innerHTML =
              `try {\n${js.innerHTML}\n` +
              `} catch (err) {\n` +
              `  throw new Error('Advertising - Baxter | AccountId: ${globalThis.Baxter.config.accountId} | PageId: ${slot.pageId} | ContainerId: ${slot.containerId} | Slot: ${slot.id} | ' + err);\n` +
              `}`;
            script.type = 'text/javascript';
            script.async = true;
            head.appendChild(script);
          });

          Placeholder.remove(slot.containerId);
          window.dispatchEvent(
            buildSlotRenderedEvent(slot.pageId, slot.containerId, slot.id, slot.status, {}, false, id)
          );
          return html;
        } catch (e) {
          console.error('[SLOTS][ADMANAGER STATIC][CREATE] rendering html error', e);
          newRelicMetrics.reportError(NewRelicError.ADMANAGER_STATIC_RENDERING_ERROR, {
            message: 'rendering html error',
            pageId: slot.pageId,
            containerId: slot.containerId,
            providerId: id,
          });
          throw e;
        }
      } else {
        window.dispatchEvent(buildSlotRenderedEvent(slot.pageId, slot.containerId, slot.id, slot.status, {}, true, id));
        return slot.external;
      }
    } else {
      console.error('[SLOTS][ADMANAGER STATIC][CREATE] container not visible');
      newRelicMetrics.reportError(NewRelicError.ADMANAGER_STATIC_CONTAINER_NOT_VISIBLE, {
        message: 'container not visible',
        pageId: slot.pageId,
        containerId: slot.containerId,
        providerId: id,
      });
      throw new Error('[SLOTS] container not visible');
    }
  } else {
    console.error('[SLOTS][ADMANAGER STATIC][CREATE] container not found');
    newRelicMetrics.reportError(NewRelicError.ADMANAGER_STATIC_CONTAINER_DIV_NOT_FOUND, {
      message: 'container not found',
      pageId: slot.pageId,
      containerId: slot.containerId,
      providerId: id,
    });
    throw new Error('[SLOTS] container not found');
  }
};

export const refresh = async (slots: AdManagerStaticSlot[] = []): Promise<boolean> => {
  console.info('[SLOTS][ADMANAGER STATIC][REFRESH]', slots);
  const success = await remove(slots);
  if (success) {
    await Promise.all(slots.map(async (slot) => create(slot)));
    return true;
  }
  return false;
};

export const remove = (slots: Slot[] = []): boolean => {
  console.info('[SLOTS][ADMANAGER STATIC][REMOVE]', slots);
  slots.forEach((slot) => {
    const div = Html.getElementById(slot.innerId);
    if (div) {
      div.innerHTML = '';
    }
  });
  return true;
};

export const clear = (): boolean => {
  console.info('[SLOTS][ADMANAGER STATIC][CLEAR]');
  const slots = Object.values(State.getSlots()).filter((slot) => slot.provider === id);
  return remove(slots);
};
