import { transformTargeting } from 'BaxterScript/version/web/provider/TransformTargeting';
import { creativeSources } from 'BaxterScript/version/web/provider/admanager/CreativeSources';
import * as Strings from 'BaxterScript/helper/string/String';
import * as State from 'BaxterScript/version/web/core/State';
import * as Html from 'BaxterScript/helper/browser/Html';
import { buildSlotRenderedEvent } from 'BaxterScript/version/web/core/SlotRenderedEventListener';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { Config } from 'BaxterScript/types/Config';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import { AdManagerConfig, AdManagerSizesConfig } from 'BaxterScript/types/ProviderSettings/AdManager';
import { AdManagerTransformedSizesItem, AdMangerAdToShow, AdMangerSlot, Callbacks } from 'BaxterScript/types/Slot';
import Placeholder from '../../feature/Placeholder';

interface AdManagerCreativePreview {
  hasPreview: boolean;
  previewAds: AdMangerAdToShow[];
}

export const id = Providers.AD_MANAGER;

export const webpackExclude = (config: Config): boolean =>
  !(
    Object.values(config.slots.provider?._ ?? {}).includes(id) ||
    Object.values(config.slots.provider ?? {}).includes(id)
  );

const transformSizes = (sizes: (string | number)[]): ({ width: number; height: number } | number)[] => {
  console.info('[SLOTS][ADMANAGER][TRANSFORMSIZES]', sizes);
  const mappedSizes = sizes.map((item) => {
    if (Strings.isString(item) && (item as string).includes('x')) {
      const widthHeight = (item as string).split('x');
      const isIntegers = widthHeight.every((element) => Strings.isNumeric(element));
      if (isIntegers) {
        return {
          width: parseInt(widthHeight[0], 10),
          height: parseInt(widthHeight[1], 10),
        };
      }
    }
    return item as number;
  });
  return mappedSizes.reverse();
};

const transformTypeSizes = (
  type: string,
  sizesConfig: AdManagerSizesConfig,
  transformedSizes: AdManagerTransformedSizesItem[]
): void => {
  console.info('[SLOTS][ADMANAGER][TRANSFORMTYPESIZES]', type, sizesConfig, transformedSizes);
  if (sizesConfig[type]?.enabled) {
    transformedSizes.push({
      type: type.toUpperCase(),
      dimensions: transformSizes(sizesConfig[type].sizes),
    });
  }
};

export const transform = async (
  pageId: string,
  containerId: string,
  slotId: string,
  params: TargetingParams
): Promise<Partial<AdMangerSlot>> => {
  console.info('[SLOTS][ADMANAGER][TRANSFORM]', pageId, containerId, slotId, params);
  const providerSettings = globalThis.Baxter.config.slots?.providerSettings?.[id] as AdManagerConfig;
  const providerConfig = globalThis.Baxter.config.providers[id];
  const transformedTargeting = {
    targeting: {},
  } as Partial<AdMangerSlot>;
  transformTargeting(
    transformedTargeting,
    providerSettings,
    providerConfig?.settings,
    pageId,
    containerId,
    slotId,
    params
  );
  const core = globalThis.Baxter.context.configurationService.getById(
    providerSettings?.core || {},
    pageId,
    containerId,
    slotId
  );
  let targeting = {};
  if (providerSettings?.targeting) {
    targeting = globalThis.Baxter.context.configurationService.getById(
      providerSettings?.targeting || {},
      pageId,
      containerId,
      slotId
    );
  }
  let sizes = {};
  const transformedSizes = [];
  if (providerSettings?.sizes) {
    sizes = globalThis.Baxter.context.configurationService.getById(
      providerSettings?.sizes || {},
      pageId,
      containerId,
      slotId
    );
    ['banner', 'native'].forEach((type) => {
      transformTypeSizes(type, sizes, transformedSizes);
    });
  }
  return {
    [id]: {
      providerConfig,
      config: {
        core,
        targeting,
        sizes,
      },
      transformed: {
        targeting: transformedTargeting.targeting as TargetingParams,
        sizes: transformedSizes,
      },
      state: {},
      callbacks: {} as Callbacks,
    },
  };
};

const fetchAds = async (slot: AdMangerSlot): Promise<AdMangerAdToShow[]> => {
  console.info('[SLOTS][ADMANAGER][FETCHADS]', slot);
  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      account: slot[id].providerConfig.settings.accountId,
      ...(slot[id].transformed.targeting.sessionLong
        ? { cid: slot[id].transformed.targeting.sessionLong as string }
        : {}),
    },
    body: JSON.stringify({
      slotHash: slot[id].config.core.hash,
      adCount: slot[id].config.core.adCount,
      appId: `${globalThis.Baxter.config.accountId}${globalThis.Baxter.config.appId}`.toUpperCase(),
      types: slot[id].transformed.sizes,
      customCriteria: Object.fromEntries(
        Object.entries(slot[id].transformed.targeting).map(([k, v]) => [
          k.toLowerCase(),
          // eslint-disable-next-line no-nested-ternary
          Array.isArray(v)
            ? v.map((vv) => (typeof vv === 'string' ? vv.toLowerCase() : vv))
            : typeof v === 'string'
              ? v.toLowerCase()
              : v,
        ])
      ),
      ...(slot[id].transformed.targeting.search_engine_input
        ? { searchEngineInput: [slot[id].transformed.targeting.search_engine_input] }
        : {}),
    }),
  };
  const response = await fetch(slot[id].providerConfig.settings.adRequestUrl, request);
  if (response.ok) {
    return (await response.json()).data;
  }
  throw new Error(`[SLOTS] Failed to fetch ad ${response.status} ${response.statusText}`);
};

const track = async (trackingUrl: string, eventType: string, showId: string): Promise<Response> =>
  fetch(`${trackingUrl}/v1/${eventType}/${showId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'no-cors',
  });

const onClickHandler = (trackingUrl: string, showId: string, destinationUrl: string) => {
  let preventDoubleClick = false;
  let preventMultipleClickTrackingRequests = false;
  return async (event) => {
    try {
      event.preventDefault();
      console.info('[SLOTS][ADMANAGER][ONCLICKHANDLER]', trackingUrl, showId, destinationUrl);
      if (!preventDoubleClick) {
        preventDoubleClick = true;
        if (!preventMultipleClickTrackingRequests) {
          preventMultipleClickTrackingRequests = true;
          await track(trackingUrl, 'click', showId);
        }
        window.open(destinationUrl, '_blank');
        preventDoubleClick = false;
      }
    } catch (e) {
      console.error('[SLOTS][ADMANAGER][ONCLICKHANDLER]', e);
      newRelicMetrics.reportError(NewRelicError.ADMANAGER_CLICK_HANDLER_ERROR, { message: (e as Error).message });
    }
  };
};

const renderAds = async (slot: AdMangerSlot, adsToShow: AdMangerAdToShow[]): Promise<void> => {
  console.info('[SLOTS][ADMANAGER][RENDERADS]', slot, adsToShow);
  const div = Html.getElementById(slot.innerId);
  if (div) {
    slot.filled = true;
    div.style.display = '';
    const creative = JSON.parse(adsToShow[0].creative);
    if (creative.type === 'BANNER') {
      const innerId = `${slot.innerId}-banner`;
      div.innerHTML = `<a id="${innerId}"><img src="${creative.url}" width="${creative.size[0]}" height="${creative.size[1]}" alt="Advertising"/></a>`;
      const image = Html.getElementById(innerId);
      image?.addEventListener(
        'click',
        onClickHandler(slot[id].providerConfig.settings.adTrackingUrl, adsToShow[0].showId, creative.destinationUrl)
      );
      await track(slot[id].providerConfig.settings.adTrackingUrl, 'view', adsToShow[0].showId);
    } else if (creative.type === 'NATIVE') {
      const htmlResponse = await fetch(creative.url);
      const html = await htmlResponse.text();
      div.innerHTML = html.replace(/<script[^>]*>(?:[^<]+|<(?!\/script>))*<\/script>/gi, '');
      const onClick = onClickHandler(
        slot[id].providerConfig.settings.adTrackingUrl,
        adsToShow[0].showId,
        creative.destinationUrl
      );
      const parser = document.createElement('div');
      parser.innerHTML = html;
      const scripts = parser.getElementsByTagName('script');
      [...scripts].forEach((js) => {
        const script = document.createElement('script');
        script.innerHTML =
          `try {\n${js.innerHTML}\n` +
          `} catch (err) {\n` +
          `  throw new Error('Advertising - Baxter SelfServe | AccountId: ${globalThis.Baxter.config.accountId} | PageId: ${slot.pageId} | ContainerId: ${slot.containerId} | Slot: ${slot.id} | ' + err);\n` +
          `}`;
        script.type = 'text/javascript';
        script.async = true;
        div.appendChild(script);
      });
      const elements = div.getElementsByClassName('baxter-destination-click-handler');
      if (elements) {
        for (const element of elements) {
          element.addEventListener('click', onClick);
        }
      }
      await track(slot[id].providerConfig.settings.adTrackingUrl, 'view', adsToShow[0].showId);
    }
    Placeholder.remove(slot.containerId);
  }
};

const creativePreview = (slot: AdMangerSlot): AdManagerCreativePreview => {
  console.info('[SLOTS][ADMANAGER][CREATIVEPREVIEW]', slot);
  const preview = {
    hasPreview: false,
    previewAds: [],
  } as AdManagerCreativePreview;
  const query = window?.location?.search;
  if (!query || !slot) {
    return preview;
  }
  const urlParams = new URLSearchParams(query);
  const baxterCreativeUrl = urlParams.get('baxterCreativeUrl') ?? '';
  const baxterContainerId = urlParams.get('baxterContainerId') ?? '';
  const creativeUrl = decodeURIComponent(baxterCreativeUrl);
  const allowedSource = creativeSources.some((source) => creativeUrl.startsWith(source));
  if (!allowedSource) {
    console.debug('[SLOTS][ADMANAGER][CREATIVEPREVIEW] creative source path not allowed', creativeUrl);
    return preview;
  }
  preview.hasPreview = !!baxterContainerId && !!creativeUrl && baxterContainerId === slot.containerId;
  preview.previewAds = [
    {
      creative: JSON.stringify({ type: 'NATIVE', url: creativeUrl }),
      showId: '',
    },
  ];

  if (preview.hasPreview) {
    console.debug('[SLOTS][ADMANAGER][CREATIVEPREVIEW] hasPreview', baxterContainerId, creativeUrl);
  }
  return preview;
};

export const create = async (slot: AdMangerSlot): Promise<unknown> => {
  console.info('[SLOTS][ADMANAGER][CREATE]', slot);
  const { hasPreview, previewAds } = creativePreview(slot);
  slot[id].state.adsToShow = !hasPreview ? await fetchAds(slot) : previewAds;
  const isEmpty = (slot[id].state.adsToShow?.length || 0) === 0;
  window.dispatchEvent(buildSlotRenderedEvent(slot.pageId, slot.containerId, slot.id, slot.status, {}, isEmpty, id));
  if (slot[id].state.adsToShow?.length) {
    if (!slot.status.lazyLoad || slot.status.visible) {
      await renderAds(slot, slot[id].state.adsToShow);
    }
  }
  return true;
};

export const refresh = async (slots: AdMangerSlot[] = []): Promise<boolean> => {
  console.info('[SLOTS][ADMANAGER][REFRESH]', slots);
  await Promise.all(
    slots.map(async (slot) => {
      if (slot[id].state.adsToShow?.length && !slot.filled) {
        await renderAds(slot, slot[id].state.adsToShow);
      } else {
        const { hasPreview, previewAds } = creativePreview(slot);
        slot[id].state.adsToShow = !hasPreview ? await fetchAds(slot) : previewAds;
        if (slot[id].state.adsToShow?.length) {
          await renderAds(slot, slot[id].state.adsToShow);
        }
      }
    })
  );
  return true;
};

export const remove = (slots: AdMangerSlot[] = []): boolean => {
  console.info('[SLOTS][ADMANAGER][REMOVE]', slots);
  slots.forEach((slot) => {
    Html.clearElement(slot.innerId);
  });
  return true;
};

export const clear = (): boolean => {
  console.info('[SLOTS][ADMANAGER][CLEAR]');
  const slots = State.getSlots();
  const adManagerSlots = Object.keys(slots)
    .filter((containerId) => slots[containerId].provider === id)
    .map((containerId) => slots[containerId]) as AdMangerSlot[];
  return remove(adManagerSlots);
};
