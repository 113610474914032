import { isEmpty } from 'lodash';
import * as Html from 'BaxterScript/helper/browser/Html';
import * as State from 'BaxterScript/version/web/core/State';
import { clone } from 'BaxterScript/helper/object/Object';
import { query } from 'BaxterScript/helper/query/Condition';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Config } from 'BaxterScript/types/Config';

export const webpackExclude = (config: Config): boolean => false;

export const getPlaceholderId = (containerId, pageId, params) => {
  let defaultPlaceholder: null | string = null;
  let matchedPlaceholder: null | string = null;
  const containers = globalThis.Baxter.config.containers[pageId] || [];
  const container = containers.find(({ id }) => containerId === id);

  const queryParams = clone({
    ...State.getUser(),
    ...State.getPageParams(),
    params,
  });

  if (container?.placeholders && container?.placeholders.map) {
    for (const placeholder of container.placeholders.map) {
      const { rule } = placeholder;
      if (rule?.condition && query(rule?.condition, queryParams)) {
        matchedPlaceholder = placeholder.defaultDesktop || placeholder.defaultMobile ? 'default' : placeholder.id;
      } else if (!rule?.condition) {
        defaultPlaceholder = 'default';
      }
    }
  }

  return matchedPlaceholder || defaultPlaceholder;
};

const applyToPage = (pageId, params = {}) => {
  if (pageId) {
    const containers = globalThis.Baxter.config.containers || {};
    const pageContainers = containers[pageId] || [];

    pageContainers.forEach((container) => {
      apply(container.id, pageId, params);
    });
  }
};

export const apply = (containerId, pageId, params = {}) => {
  const placeholderId = getPlaceholderId(containerId, pageId, params);

  if (!placeholderId) {
    console.debug('[SLOTS][PLACEHOLDER] NO MATCHING PLACEHOLDER FOUND', containerId, pageId);
    return;
  }
  console.debug('[SLOTS][PLACEHOLDER] APPLYING', containerId, pageId, placeholderId);
  const placeholderBaseClass = `baxter-placeholder--${pageId}--${containerId}`;
  const placeholderClass = `${placeholderBaseClass}--${placeholderId}`;
  const container = Html.getElementById(containerId);

  if (container) {
    if (placeholderId === 'default') {
      console.debug('[SLOTS][PLACEHOLDER] Set default placeholder', container, `${placeholderBaseClass}--default`);
      Html.addClass(container, `${placeholderBaseClass}--default`);
    } else {
      console.debug('[SLOTS][PLACEHOLDER] Set custom placeholder', container, placeholderClass);
      Html.addClass(container, placeholderClass);
      Html.removeClass(container, `${placeholderBaseClass}--default`);
    }
    container.style.backgroundImage = '';

    console.debug('[SLOTS][PLACEHOLDER] LINK', containerId, pageId);
    if (getContainerRedirectUrl(pageId, containerId, params)) {
      container.addEventListener('click', setContainerRedirectUrl);
      console.debug('[SLOTS][PLACEHOLDER] LINKED', containerId, pageId);
    }
  } else {
    console.debug('[SLOTS][PLACEHOLDER] NO CONTAINER');
  }
};

export const remove = (containerId) => {
  const container = Html.getElementById(containerId);
  if (container) {
    container.classList.forEach((className) => {
      if (className.startsWith('baxter-placeholder')) {
        Html.removeClass(container, className);
        console.debug('[SLOTS][PLACEHOLDER][REMOVE] REMOVED', containerId);
      }
    });
    container.style.backgroundImage = 'none';
    container.removeEventListener('click', setContainerRedirectUrl);
  }
};

const getContainerRedirectUrl = (pageId, containerId, params = {}) => {
  const placeholderId = getPlaceholderId(containerId, pageId, params);
  return globalThis.Baxter.context.configurationService.getPlaceholder(pageId, containerId, placeholderId)?.redirectUrl;
};

const setContainerRedirectUrl = (event, params = {}) => {
  try {
    console.info('[SLOTS][PLACEHOLDER][SETCONTAINERREDIRECTURL]', params);
    const parameters = isEmpty(params) ? State.getPageParams() : params;
    const pageId = State.getPageId();
    const containerId = event?.currentTarget?.id;
    const redirectUrl = getContainerRedirectUrl(pageId, containerId, parameters);
    window.open(redirectUrl, '_blank');
  } catch (e) {
    console.error('[SLOTS][PLACEHOLDER][SETCONTAINERREDIRECTURL]', e);
    newRelicMetrics.reportError(NewRelicError.PLACEHOLDER_SET_CONTAINER_REDIRECT_URL_ERROR, {
      message: (e as Error).message,
    });
  }
};

export default {
  applyToPage,
  apply,
  remove,
};
